import React, { useEffect, useState } from "react";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import { FabricImage, Rect, Circle, Text, PencilBrush, Line } from "fabric";
import {
  FaCircle,
  FaPencilAlt,
  FaArrowUp,
  FaArrowDown,
  FaSquare,
} from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FabricCanvas = ({ imageSrc, onSave, onClose }) => {
  const { editor, onReady } = useFabricJSEditor();
  const [brushColor, setBrushColor] = useState("#000000"); // Couleur initiale du pinceau
  const [isDrawingMode, setIsDrawingMode] = useState(false);
  const [selectedObject, setSelectedObject] = useState(null);
  const [currentColor, setCurrentColor] = useState("#000000"); // Couleur initiale
  const [showCoffretPopup, setShowCoffretPopup] = useState(false);
  const [coffrets, setCoffrets] = useState([
    {
      id: 1,
      name: "Coffret 1",
      image: process.env.PUBLIC_URL + "/uploads/coffret/Image1.png",
    },
    {
      id: 2,
      name: "Coffret 2",
      image: process.env.PUBLIC_URL + "/uploads/coffret/Image2.png",
    },
    {
      id: 3,
      name: "Coffret 3",
      image: process.env.PUBLIC_URL + "/uploads/coffret/Image3.png",
    },
    {
      id: 4,
      name: "Coffret 4",
      image: process.env.PUBLIC_URL + "/uploads/coffret/Image4.png",
    },
    {
      id: 5,
      name: "Coffret 5",
      image: process.env.PUBLIC_URL + "/uploads/coffret/Image5.png",
    },
    {
      id: 6,
      name: "Coffret 6",
      image: process.env.PUBLIC_URL + "/uploads/coffret/Image6.png",
    },
    {
      id: 7,
      name: "Coffret 7",
      image: process.env.PUBLIC_URL + "/uploads/coffret/Image7.jpg",
    },
  ]);

  let isPanning = false;
  let lastPosX, lastPosY;
  let spacePressed = false;

  useEffect(() => {
    if (editor) {
      const canvas = editor.canvas;
      canvas.preserveObjectStacking = true;

      // // Activer les guides d'alignement
      // canvas.set({
      //   snapThreshold: 10, // Distance en pixels pour l'accrochage
      //   snapAngle: 45, // Angle pour la rotation (en degrés)
      // });

      // // Événement de déplacement d'objet
      // canvas.on("object:moving", function (e) {
      //   const activeObject = e.target;
      //   const objectCenter = activeObject.getCenterPoint();
      //   const allObjects = canvas.getObjects();

      //   // Masquer les lignes guides précédentes
      //   canvas.getObjects("line").forEach((line) => {
      //     if (line.alignmentLine) {
      //       canvas.remove(line);
      //     }
      //   });

      //   allObjects.forEach((obj) => {
      //     if (obj === activeObject || !obj.visible) return;

      //     const objCenter = obj.getCenterPoint();

      //     // Alignement vertical
      //     if (Math.abs(objectCenter.x - objCenter.x) < 10) {
      //       const line = new Line(
      //         [
      //           objectCenter.x,
      //           Math.min(objectCenter.y, objCenter.y),
      //           objectCenter.x,
      //           Math.max(objectCenter.y, objCenter.y),
      //         ],
      //         {
      //           stroke: "#ff0000",
      //           strokeWidth: 1,
      //           strokeDashArray: [5, 5],
      //           selectable: false,
      //           alignmentLine: true,
      //         }
      //       );
      //       canvas.add(line);

      //       canvas.bringObjectToFront(line);

      //       // Snap à la position
      //       // activeObject.set({ left: obj.left });
      //     }

      //     // Alignement horizontal
      //     if (Math.abs(objectCenter.y - objCenter.y) < 10) {
      //       const line = new Line(
      //         [
      //           Math.min(objectCenter.x, objCenter.x),
      //           objectCenter.y,
      //           Math.max(objectCenter.x, objCenter.x),
      //           objectCenter.y,
      //         ],
      //         {
      //           stroke: "#ff0000",
      //           strokeWidth: 1,
      //           strokeDashArray: [5, 5],
      //           selectable: false,
      //           alignmentLine: true,
      //         }
      //       );
      //       canvas.add(line);
      //       canvas.bringObjectToFront(line);

      //       // Snap à la position
      //       // activeObject.set({ top: obj.top });
      //     }
      //   });

      //   //  canvas.renderAll();
      // });

      // // Supprimer les lignes guides quand on arrête le déplacement
      // canvas.on("object:modified", function () {
      //   canvas.getObjects("line").forEach((line) => {
      //     if (line.alignmentLine) {
      //       canvas.remove(line);
      //     }
      //   });
       
      // });

      // Gestion du pan avec la touche "Espace"
      const handleKeyDown = (event) => {
        if (event.code === "Space") {
          spacePressed = true;
          canvas.defaultCursor = "grab";
        }
      };

      const handleKeyUp = (event) => {
        if (event.code === "Space") {
          spacePressed = false;
          canvas.defaultCursor = "default";
        }
      };

      document.addEventListener("keydown", handleKeyDown);
      document.addEventListener("keyup", handleKeyUp);

      // Événements souris pour le pan
      canvas.on("mouse:down", (event) => {
        if (spacePressed) {
          isPanning = true;
          lastPosX = event.e.clientX;
          lastPosY = event.e.clientY;
          canvas.selection = false;
        }
      });

      canvas.on("mouse:move", (event) => {
        if (isPanning && event.e) {
          let deltaX = event.e.clientX - lastPosX;
          let deltaY = event.e.clientY - lastPosY;
          canvas.relativePan({ x: deltaX, y: deltaY });
          lastPosX = event.e.clientX;
          lastPosY = event.e.clientY;
        }
      });

      canvas.on("mouse:up", () => {
        isPanning = false;
        canvas.selection = true;
      });

      // Gestion du pan sur mobile (avec 2 doigts)
      const handleTouchStart = (event) => {
        if (event.touches.length === 2) {
          isPanning = true;
          lastPosX = event.touches[0].clientX;
          lastPosY = event.touches[0].clientY;
          canvas.selection = false;
        }
      };

      const handleTouchMove = (event) => {
        if (isPanning && event.touches.length === 2) {
          let deltaX = event.touches[0].clientX - lastPosX;
          let deltaY = event.touches[0].clientY - lastPosY;
          canvas.relativePan({ x: deltaX, y: deltaY });
          lastPosX = event.touches[0].clientX;
          lastPosY = event.touches[0].clientY;
        }
      };

      const handleTouchEnd = () => {
        isPanning = false;
        canvas.selection = true;
      };

      canvas.upperCanvasEl.addEventListener("touchstart", handleTouchStart);
      canvas.upperCanvasEl.addEventListener("touchmove", handleTouchMove);
      canvas.upperCanvasEl.addEventListener("touchend", handleTouchEnd);

      return () => {
        document.removeEventListener("keydown", handleKeyDown);
        document.removeEventListener("keyup", handleKeyUp);
        canvas.upperCanvasEl.removeEventListener(
          "touchstart",
          handleTouchStart
        );
        canvas.upperCanvasEl.removeEventListener("touchmove", handleTouchMove);
        canvas.upperCanvasEl.removeEventListener("touchend", handleTouchEnd);
      };
    }
  }, [editor]);

  const handleDeselected = (event) => {
    event?.deselected?.forEach((obj) => {
      obj.set({ opacity: 1 });
    });
    setSelectedObject(null);
  };

  useEffect(() => {
    if (editor?.canvas) {
      editor.canvas.on("selection:created", updateColorFromSelection);
      editor.canvas.on("selection:updated", updateColorFromSelection);

      editor.canvas.on("selection:cleared", handleDeselected);
    }

    if (editor?.canvas && imageSrc) {
      addImage();
    }
    return () => {
      if (editor?.canvas) {
        editor.canvas.off("selection:updated ", updateColorFromSelection);
        editor.canvas.off("selection:cleared");
      }
    };
  }, [editor, imageSrc]);

  const insertCoffret = async (imageUrl) => {
    if (!editor?.canvas) return;

    try {
      const image = await FabricImage.fromURL(imageUrl, {
        crossOrigin: "Anonymous", // Important pour les images externes
      });

      // Redimensionner l'image
      const scale = Math.min(100 / image.width, 100 / image.height);

      image.set({
        scaleX: scale,
        scaleY: scale,
        left: 100,
        top: 100,
      });

      editor.canvas.add(image);
      editor.canvas.setActiveObject(image);
      setShowCoffretPopup(false);
    } catch (error) {
      console.error("Erreur lors du chargement de l'image:", error);
    }
  };

  const updateColorFromSelection = (event) => {
    console.log("selected", selectedObject, "event", event);

    if (event.deselected) {
      handleDeselected(event);
    }

    const activeObject = editor?.canvas.getActiveObject();

    if (activeObject) {
      activeObject.set({ opacity: 0.5 });
      setSelectedObject(activeObject);
    }
  };
  const addImage = async () => {
    if (!editor?.canvas || !imageSrc) return;

    const canvas = editor.canvas;
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;

    const image = await FabricImage.fromURL(imageSrc, {}, {});

    // Récupérer les dimensions réelles de l'image
    const imgWidth = image.width;
    const imgHeight = image.height;

    // Calcul du ratio de redimensionnement tout en conservant les proportions
    const scaleX = canvasWidth / imgWidth;
    const scaleY = canvasHeight / imgHeight;

    // Appliquer le plus petit des deux ratios pour garder les proportions
    const scale = Math.min(scaleX, scaleY);

    // Appliquer les transformations (scale) à l'image
    image.set({
      scaleX: scale,
      scaleY: scale,
      left: (canvasWidth - imgWidth * scale) / 2, // Centrer l'image
      top: (canvasHeight - imgHeight * scale) / 2, // Centrer l'image
      selectable: true, // Ou false si tu veux désactiver la sélection
    });

    // Ajouter l'image au canevas
    canvas.add(image);
  };

  const bringToFront = () => {
    const activeObject = editor?.canvas.getActiveObject();
    if (activeObject) {
      editor.canvas.bringObjectToFront(activeObject);

      // activeObject.bringToFront();
      //  editor.canvas.renderAll();
    }
  };

  const sendToBack = () => {
    const activeObject = editor?.canvas.getActiveObject();
    if (activeObject) {
      editor.canvas.sendObjectToBack(activeObject);
      // editor.canvas.renderAll();
    }
  };

  const bringForward = () => {
    const activeObject = editor?.canvas.getActiveObject();
    if (activeObject) {
      editor.canvas.bringObjectForward(activeObject);
      // editor.canvas.renderAll();
    }
  };

  const sendBackward = () => {
    const activeObject = editor?.canvas.getActiveObject();
    if (activeObject) {
      editor.canvas.sendObjectBackwards(activeObject);
      //  editor.canvas.renderAll();
    }
  };

  // Fonction pour activer le dessin
  const enableDrawing = () => {
    console.log("enableDrawing");
    if (editor?.canvas) {
      // Activer le mode de dessin
      editor.canvas.isDrawingMode = true;

      // Vérifier si `freeDrawingBrush` est défini
      if (editor.canvas.freeDrawingBrush) {
        // Appliquer la couleur et la largeur du pinceau
        editor.canvas.freeDrawingBrush.color = brushColor;
        editor.canvas.freeDrawingBrush.width = 5; // Largeur du pinceau (modifiable)
        //  editor.canvas.renderAll();
        setIsDrawingMode(true);
      } else {
        // Si `freeDrawingBrush` n'est pas défini, initialiser un pinceau
        const brush = new PencilBrush(editor.canvas);
        brush.color = brushColor; // Couleur du pinceau
        brush.width = 5; // Largeur du pinceau
        editor.canvas.freeDrawingBrush = brush;
        setIsDrawingMode(true);
      }
    }
  };

  // Fonction pour changer la couleur du pinceau
  const handleColorChange = (color) => {
    setBrushColor(color);
    if (editor?.canvas && editor.canvas.isDrawingMode) {
      editor.canvas.freeDrawingBrush.color = color;
    }
  };

  // Fonction pour désactiver le dessin
  const disableDrawing = () => {
    console.log("disableDrawing");
    if (editor?.canvas) {
      editor.canvas.isDrawingMode = false;
      setIsDrawingMode(false);
    }
  };

  const addCircle = () => {
    if (!editor?.canvas) return;

    const circle = new Circle({
      radius: 30,
      fill: "red",
    });
    editor.canvas.add(circle);
    editor.canvas.bringObjectToFront(circle);
    // editor.canvas.renderAll();
  };

  const addRectangle = () => {
    if (!editor?.canvas) return;

    const rect = new Rect({
      width: 50,
      height: 50,
      fill: "blue",
    });
    editor.canvas.add(rect);
    //editor.canvas.renderAll();
  };

  const addText = () => {
    if (!editor?.canvas) return;

    const text = new Text("Texte", {
      left: 200,
      top: 100,
      fill: "black",
      fontSize: 20,
    });
    editor.canvas.add(text);
  };

  const handleSave = () => {
    if (!editor?.canvas) return;

    const editedImage = editor.canvas.toDataURL();
    onSave(editedImage);
  };

  const handleDelete = () => {
    if (!editor?.canvas) return;

    const activeObject = editor.canvas.getActiveObject();
    if (activeObject) {
      editor.canvas.remove(activeObject);
    }
  };

  const changeColor = (color) => {
    if (editor?.canvas) {
      const activeObject = editor.canvas.getActiveObject();

      if (activeObject) {
        // Vérifier si l'objet sélectionné est un objet de type "rect", "circle", ou autre
        activeObject.set({ fill: color });
        //  editor.canvas.renderAll();
      }
    }
  };
  const convertColorToHex = (color) => {
    console.log("color1", color);
    if (color && color.indexOf("rgb") !== -1) {
      const rgb = color.match(/\d+/g);
      return rgb
        ? `#${(
            (1 << 24) |
            (parseInt(rgb[0]) << 16) |
            (parseInt(rgb[1]) << 8) |
            parseInt(rgb[2])
          )
            .toString(16)
            .slice(1)}`
        : "#000000";
    }
    console.log("color", color);
    return color; // Si c'est déjà un hex, pas besoin de conversion
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center w-full h-full max-h-screen">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full h-full flex flex-col">
        <div className="mb-4 space-x-2 flex">
          <button
            onClick={() => setShowCoffretPopup(true)}
            className="p-2 bg-yellow-500 text-white rounded shadow-md hover:bg-yellow-600"
          >
            Ajouter Coffret
          </button>
          <button
            onClick={bringToFront}
            className="p-2 bg-blue-500 text-white rounded shadow-md hover:bg-blue-600"
          >
            <i className="fas fa-layer-group w-6 h-6"> </i>
          </button>
          <button
            onClick={sendToBack}
            className="p-2 bg-gray-500 text-white rounded shadow-md hover:bg-gray-600"
          >
            <i className="fas fa-layer-minus w-6 h-6"> </i>
          </button>
          <button
            onClick={bringForward}
            className="p-2 bg-green-500 text-white rounded shadow-md hover:bg-green-600"
          >
            <FaArrowUp size={20} />
          </button>
          <button
            onClick={sendBackward}
            className="p-2 bg-red-500 text-white rounded shadow-md hover:bg-red-600"
          >
            <FaArrowDown size={20} />
          </button>
          <button
            onClick={addRectangle}
            className="p-2 bg-indigo-500 text-white rounded shadow-md hover:bg-indigo-600"
          >
            <FaSquare size={20} />
          </button>
          <button type="button" onClick={addCircle} className="btn btn-primary">
            <FaCircle className="w-6 h-6 "></FaCircle>
          </button>
          <button
            type="button"
            onClick={addRectangle}
            className="btn btn-primary"
          >
            Ajouter Rectangle
          </button>
          <button type="button" onClick={addText} className="btn btn-primary">
            Ajouter Texte
          </button>
          <button
            type="button"
            onClick={handleDelete}
            className={`btn btn-danger ${selectedObject ? "block" : "hidden"}`}
          >
            Supprimer
          </button>

          <div
            className={`${
              isDrawingMode || selectedObject ? "block" : "hidden"
            } `}
          >
            <input
              type="color"
              value={
                selectedObject
                  ? convertColorToHex(selectedObject.fill)
                  : brushColor
              }
              onChange={(e) => {
                console.log("change", e.target.value);
                selectedObject
                  ? changeColor(e.target.value)
                  : handleColorChange(e.target.value);
              }}
            />
          </div>

          {/* Options de dessin */}
          <div className="drawing-options mb-4">
            <FontAwesomeIcon
              className={`w-8 h-8 cursor-pointer ${
                isDrawingMode ? "block" : "hidden"
              }`}
              icon="fa-pencil-slash"
              onClick={disableDrawing}
            />

            <FontAwesomeIcon
              className={`w-6 h-6 cursor-pointer ${
                isDrawingMode ? "hidden" : "block"
              }`}
              icon="fa-pencil"
              onClick={enableDrawing}
            />
          </div>
        </div>
        {/* Palette de couleurs */}

        <FabricJSCanvas onReady={onReady} className="flex flex-grow" />

        <div className="flex space-x-2">
          <button onClick={onClose} className="btn btn-secondary">
            Annuler
          </button>
          <button onClick={handleSave} className="btn btn-success">
            Sauvegarder
          </button>
        </div>
      </div>
      {showCoffretPopup && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-2xl w-full">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">Sélectionner un coffret</h2>
              <button
                onClick={() => setShowCoffretPopup(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div className="grid grid-cols-3 gap-4 max-h-96 overflow-y-auto">
              {coffrets.map((coffret) => (
                <div
                  key={coffret.id}
                  className="cursor-pointer hover:bg-gray-100 p-2 rounded transition-colors"
                  onClick={() => insertCoffret(coffret.image)}
                >
                  <img
                    src={coffret.image}
                    alt={coffret.name}
                    className="w-full h-32 object-contain mb-2"
                    loading="lazy"
                  />
                  <p className="text-center text-sm">{coffret.name}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FabricCanvas;
