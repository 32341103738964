import React, { Fragment, useRef, useState } from "react";
import {
  PencilIcon,
  TrashIcon,
  PlusCircleIcon,
  CheckIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import FabricCanvas from "../Components/FabricCanvas";
import { descTravaux, materiaux } from "../Data";
import Camera from "../Components/Camera";
import {
  Dialog,
  TransitionChild,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { FaArrowLeft, FaList, FaWrench } from "react-icons/fa";
import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import ImageModule from "docxtemplater-image-module-free";
import { saveAs } from "file-saver";
import imageSize from "image-size";
import { Buffer } from "buffer";

const FormulaireTechnicien = () => {
  const [tab, setTab] = useState("informations");
  const [recapEnedis, setRecapEnedis] = useState([]); // Stocke les éléments Enedis
  const [recapClient, setRecapClient] = useState([]);
  const [inputValues, setInputValues] = useState([]); // Valeurs des inputs
  const [selectValues, setSelectValues] = useState([]); // Valeurs des selects
  const [popupData, setPopupData] = useState(null); // Données pour la popup
  const [data, setData] = useState(descTravaux);
  const [text, setText] = useState(""); // Texte du formulaire
  const [images, setImages] = useState([]); // Liste des images ajoutées
  const [imageFiles, setImageFiles] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null); // Image à éditer
  const [recapMateriaux, setRecapMateriaux] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [quantityPopup, setQuantityPopup] = useState(null);
  const quantityRef = useRef(null);
  const [informations, setInformations] = useState({
    typeDossier: "",
    date: new Date().toISOString().split("T")[0],
    affaire: "",
    commune: "",
    adresse: "",
    poste: "",
    GDOPoste: "",
    client: "",
    tel: "",
    mail: "",
  });
  // Ajoutez ces états
  const [imageTypes] = useState([
    "Borne",
    "Extérieur",
    "Tableau",
    "Eplans_petite_echelle",
    "Eplans_grande_echelle",
  ]);

  const [imagesParType, setImagesParType] = useState({
    Borne: [],
    Extérieur: [],
    Tableau: [],
    Eplans_petite_echelle: [],
    Eplans_grande_echelle: [],
  });

  // Ajoutez l'état pour gérer la sélection du type
  const [selectedImageType, setSelectedImageType] = useState(null);

  const handleUpdateInformations = (key, value) => {
    setInformations((prev) => ({ ...prev, [key]: value }));
  };

  const generateDocument = async () => {
    try {
      // Charger le template
      const response = await fetch("/template.docx");
      const blob = await response.blob();
      const arrayBuffer = await blob.arrayBuffer();
      // Configuration du module d'images

      const imagesDimensions = {}; // Stocker les tailles calculées
      // Préparer les données des images
      const imagesData = {};
      Object.entries(imagesParType).forEach(([type, images]) => {
        if (images.length > 0) {
          // Important : on doit envoyer un objet avec la propriété 'image'
          imagesData[type.toLowerCase()] = images.map(
            (img) => img.src.split(",")[1] // Ne gardez que la partie base64
          );
        }
      });

      const imageModule = new ImageModule({
        centered: true,
        getImage: (tagValue) => {
          // Convertir la base64 en tableau de bytes
          const binaryString = atob(tagValue);
          const len = binaryString.length;
          const bytes = new Uint8Array(len);
          for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }
          return bytes.buffer; // Retourner un ArrayBuffer
        },
        getSize: (img, tagValue, tagName) => {
              const buffer = Buffer.from(new Uint8Array(img));
              const dimensions = imageSize(buffer);
              const maxWidth = 718; // 14.25 cm en pixels
              const maxHeight = 538; // 19 cm en pixels
              let width = dimensions.width;
              let height = dimensions.height;

              // Calculer le ratio pour redimensionner proportionnellement
              const widthRatio = maxWidth / width;
              const heightRatio = maxHeight / height;
              const ratio = Math.min(widthRatio, heightRatio);

              width = Math.round(width * ratio);
              height = Math.round(height * ratio);

              return [width, height];
        },
      });

      // Créer un nouveau document à partir du template
      const zip = new PizZip(arrayBuffer);
      const doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,

        modules: [imageModule],
      });

      // Préparer les données à insérer
      const data = {
        date: informations.date,
        affaire: informations.affaire,
        commune: informations.commune,
        adresse: informations.adresse,
        type_dossier: informations.typeDossier,
        nom_poste: informations.poste,
        GDO_poste: informations.GDOPoste,
        client: informations.client,
        tel_client: informations.tel,
        mail_client: informations.mail,
        travauxEnedis: recapEnedis.map((item) => item.value),
        travauxClient: recapClient.map((item) => item.value),
        materiaux: recapMateriaux.map((item) => item.value),
        borne: imagesData.borne || false,
        exterieur: imagesData.exterieur || false,
        tableau: imagesData.tableau || false,
        eplanspetite: imagesData.eplans_petite_echelle || false,
        eplansgrande: imagesData.eplans_grande_echelle || false,
      };

      console.log("Data envoyée au template:", data); // Pour déboguer

      // Remplacer les variables dans le template
      await doc.renderAsync(data);

      // Générer le document final
      const generatedDoc = doc.getZip().generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });

      // Télécharger le fichier
      saveAs(generatedDoc, "rapport_intervention.docx");
    } catch (error) {
      console.error("Erreur lors de la génération du document:", error);
      if (error.properties && error.properties.errors) {
        error.properties.errors.forEach((e) => {
          console.log("Erreur dans le template:", e.properties.explanation);
        });
      }
      toast.error("Erreur lors de la génération du document");
    }
  };

  const ImageTypeSelector = ({ onSelect, onCancel }) => {
    const handleTypeSelection = (type) => {
      if (!imageFiles) return;

      imageFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          setImagesParType((prev) => ({
            ...prev,
            [type]: [
              ...prev[type],
              {
                src: e.target.result,
                name: file.name,
                type: type,
              },
            ],
          }));
        };
        reader.readAsDataURL(file);
      });

      setSelectedImageType(null);
      setImageFiles(null);
    };

    return (
      <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
          <h4 className="text-lg font-semibold mb-4">Type de photo</h4>
          <div className="grid grid-cols-2 gap-4">
            {imageTypes.map((type) => (
              <button
                key={type}
                className="btn btn-outline"
                onClick={() => handleTypeSelection(type)}
              >
                {type.replace(/_/g, " ")}
              </button>
            ))}
          </div>
          <button
            onClick={() => {
              onCancel();
              setImageFiles(null);
            }}
            className="btn btn-secondary mt-4 w-full"
          >
            Annuler
          </button>
        </div>
      </div>
    );
  };

  // Gérer l'ajout d'une image
  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    if (files.length === 0) return;

    setImageFiles(files);
    setSelectedImageType(true);
  };

  // Gérer la modification d'une image avec Fabric.js
  const handleEditImage = (image, type) => {
    setSelectedImage(image);
    setSelectedImageType(type);
  };

  const addPhoto = (photo) => {
    setImages((prevPhotos) => [...prevPhotos, photo]);
  };

  const displayData = (item, index) => {
    return (
      <label key={index} className="flex mb-2">
        <PlusCircleIcon
          className="mr-2 w-6 h-6 flex-shrink-0"
          onClick={() => handleCheckboxChange(item)}
        />
        {item?.label ? item?.label : item?.value}
      </label>
    );
  };

  // Fonction pour gérer le changement des cases à cocher
  const handleCheckboxChange = (item) => {
    setData((prev) =>
      prev.map((data) => (data.value === item.value ? { ...data } : data))
    );

    if (item.value.includes("XX") || item.value.includes("XXX")) {
      setPopupData(item);
      setInputValues(
        new Array((item.value.match(/\bXX\b/g) || []).length).fill("")
      );
      setSelectValues(
        new Array((item.value.match(/\bXXX\b/g) || []).length).fill("")
      );
      return;
    }

    // if (checked) {
    if (item.type === "enedis") {
      setRecapEnedis((prev) => [...prev, { ...item, original: item.value }]);
    } else {
      setRecapClient((prev) => [...prev, { ...item, original: item.value }]);
    }
    toast.success("Element ajouté à la liste");

    if (item.codesMateriaux) {
      item.codesMateriaux.forEach((codeMateriel) => {
        const materiel = Object.values(materiaux)
          .flat()
          .find((m) => m.code === codeMateriel);
        if (materiel) {
          console.log(materiel);
          //ajouter le materiel à la description en texte :
          const materielText = `Q1 - ${materiel.description} - ${materiel.code} `;
          setRecapMateriaux((prev) => [...prev, { value: materielText }]);
        }
      });
    }

    // } else {
    //   console.log(recapClient, item);
    //   if (item.type === "enedis") {
    //     setRecapEnedis((prev) => prev.filter((i) => i.original !== item.value));
    //   } else {
    //     setRecapClient((prev) => prev.filter((i) => i.original !== item.value));
    //   }
    // }
  };

  // Gestion des inputs pour les "XX"
  const handleInputChange = (event, index) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = event.target.value;
    setInputValues(newInputValues);
  };

  // Gestion des selects pour les "XXX"
  const handleSelectChange = (event, index) => {
    const newSelectValues = [...selectValues];
    newSelectValues[index] = event.target.value;
    setSelectValues(newSelectValues);
  };

  // Validation des valeurs
  const handleSubmitInput = () => {
    if (
      inputValues.some((v) => v === "") ||
      selectValues.some((v) => v === "")
    ) {
      return; // Ne rien faire si un champ est vide
    }

    if (popupData) {
      let updatedName = popupData.value;

      inputValues.forEach((value) => {
        updatedName = updatedName.replace("XX", value);
      });

      selectValues.forEach((value) => {
        updatedName = updatedName.replace("XXX", value);
      });

      if (popupData.type === "enedis") {
        setRecapEnedis((prev) => [
          ...prev,
          { value: updatedName, original: popupData.value },
        ]);
      } else {
        setRecapClient((prev) => [
          ...prev,
          { value: updatedName, original: popupData.value },
        ]);
      }
      toast.success("Element ajouté à la liste");
      setPopupData(null);
      setInputValues([]);
      setSelectValues([]);
    }
  };

  return (
    <div className="p-4 md:max-w-2xl max-w-screen mx-auto bg-white shadow-md rounded-lg">
      <h2 className="text-xl font-bold mb-4">Formulaire d'Intervention</h2>

      <div className="flex border-b mb-4 overflow-auto">
        <button
          onClick={() => setTab("informations")}
          className={`p-2 ${
            tab === "informations" ? "border-b-2 border-blue-500" : ""
          }`}
        >
          Informations Générales
        </button>
        <button
          onClick={() => setTab("coordonnees")}
          className={`p-2 ${
            tab === "coordonnees" ? "border-b-2 border-blue-500" : ""
          }`}
        >
          Coordonnées Client
        </button>
        <button
          onClick={() => setTab("travaux")}
          className={`p-2 ${
            tab === "travaux" ? "border-b-2 border-blue-500" : ""
          }`}
        >
          Travaux
        </button>
        <button
          onClick={() => setTab("Photos")}
          className={`p-2 ${
            tab === "Photos" ? "border-b-2 border-blue-500" : ""
          }`}
        >
          Photos
        </button>
        <button
          onClick={() => {
            setDrawerOpen(true);
            setTab("materiaux");
          }}
          className={`p-2 ${
            tab === "materiaux" ? "border-b-2 border-blue-500" : ""
          }`}
        >
          Materiaux
        </button>
      </div>

      <div>
        {tab === "informations" && (
          <div>
            <label className="block mb-2">Type de dossier</label>
            <select
              className="select select-bordered w-full mb-4"
              onChange={(e) =>
                handleUpdateInformations("typeDossier", e.target.value)
              }
            >
              <option value="">Sélectionnez une option</option>
              <option>Branchement neuf</option>
              <option>Modification de branchement</option>
            </select>

            <label className="block mb-2">Date :</label>
            <input
              type="date"
              defaultValue={new Date().toISOString().split("T")[0]}
              onChange={(e) => handleUpdateInformations("date", e.target.value)}
              className="input input-bordered w-full mb-4"
            />
            <label className="block mb-2">Affaire :</label>
            <input
              type="text"
              className="input input-bordered w-full mb-4"
              onChange={(e) =>
                handleUpdateInformations("affaire", e.target.value)
              }
            />

            <label className="block mb-2">Adresse :</label>
            <input
              type="text"
              className="input input-bordered w-full mb-4"
              onChange={(e) =>
                handleUpdateInformations("adresse", e.target.value)
              }
            />
            <label className="block mb-2">Commune :</label>
            <input
              type="text"
              className="input input-bordered w-full mb-4"
              onChange={(e) =>
                handleUpdateInformations("commune", e.target.value)
              }
            />
            <label className="block mb-2">Nom du poste HTA/BT :</label>
            <input
              type="text"
              className="input input-bordered w-full mb-4"
              onChange={(e) =>
                handleUpdateInformations("poste", e.target.value)
              }
            />
            <label className="block mb-2">Code GDO du poste :</label>
            <input
              type="text"
              className="input input-bordered w-full mb-4"
              onChange={(e) =>
                handleUpdateInformations("GDOPoste", e.target.value)
              }
            />
          </div>
        )}

        {tab === "coordonnees" && (
          <div>
            <label className="block mb-2">Client :</label>
            <input
              type="text"
              className="input input-bordered w-full mb-4"
              onChange={(e) =>
                handleUpdateInformations("client", e.target.value)
              }
            />

            <label className="block mb-2">Tel :</label>
            <input
              type="tel"
              className="input input-bordered w-full mb-4"
              onChange={(e) => handleUpdateInformations("tel", e.target.value)}
            />

            <label className="block mb-2">Mail :</label>
            <input
              type="email"
              className="input input-bordered w-full mb-4"
              onChange={(e) => handleUpdateInformations("mail", e.target.value)}
            />
          </div>
        )}
        {tab === "travaux" && (
          <div>
            <h3 className="text-lg font-semibold">À la charge d'Enedis :</h3>
            {data
              ?.filter((item) => item.type == "enedis")
              ?.map((item, index) => displayData(item, index))}

            <h3 className="text-lg font-semibold">À la charge du client :</h3>
            <div className="justify-between gap-1">
              {data
                ?.filter((item) => item.type != "enedis")
                ?.map((item, index) => displayData(item, index))}
            </div>

            {/* Récapitulatif */}
            {(recapEnedis.length > 0 || recapClient.length > 0) && (
              <div className="mt-6 p-4 bg-gray-100 rounded-md">
                <h3 className="text-lg font-semibold mb-2">Récapitulatif :</h3>
                {recapEnedis.length > 0 && (
                  <div>
                    <h4 className="text-md font-semibold">
                      À la charge d'Enedis :
                    </h4>
                    <ul className="list-disc pl-5">
                      {recapEnedis.map((item, index) => (
                        <div className="flex w-full justify-between items-start pb-2 mb-2 border-b-2 border-gray-300  ">
                          <li key={index}>
                            {item.value.split(/(?=[-:])/g).map((text, i) => (
                              <React.Fragment key={i}>
                                {text}
                                {(text.includes("-") || text.includes(":")) && (
                                  <br />
                                )}
                              </React.Fragment>
                            ))}
                          </li>
                          <TrashIcon
                            className="h-6 w-6  text-red-200 hover:text-red-600 cursor-pointer flex-shrink-0 "
                            onClick={() => {
                              setRecapEnedis((prevRecap) =>
                                prevRecap.filter((_, i) => i !== index)
                              );
                            }}
                          />
                        </div>
                      ))}
                    </ul>
                  </div>
                )}
                {recapClient.length > 0 && (
                  <div className="mt-4">
                    <h4 className="text-md font-semibold">
                      À la charge du client :
                    </h4>
                    <ul className="list-disc pl-5">
                      {recapClient.map((item, index) => (
                        <div className="flex w-full justify-between items-start pb-2 mb-2 border-b-2 border-gray-300  ">
                          <li key={index}>
                            {item.value.split(/(?=[-:])/g).map((text, i) => (
                              <React.Fragment key={i}>
                                {text}
                                {(text.includes("-") || text.includes(":")) && (
                                  <br />
                                )}
                              </React.Fragment>
                            ))}
                          </li>
                          <TrashIcon
                            className=" mt-0 h-6 w-6   text-red-200 hover:text-red-600 cursor-pointer flex-shrink-0 "
                            onClick={() => {
                              setRecapClient((prevRecap) =>
                                prevRecap.filter((_, i) => i !== index)
                              );
                            }}
                          />
                        </div>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {tab === "Photos" && (
          <div style={{ padding: "20px", textAlign: "center" }}>
            {/* Upload d'image */}
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              multiple // Permet la sélection multiple
              className="file-input file-input-bordered w-full mb-4"
            />

            {/* Affichage des images avec option d'édition */}
            <div
              style={{ display: "flex", flexWrap: "wrap", marginTop: "20px" }}
            >
              {/* {images.map((img, index) => (
                <div
                  key={index}
                  style={{ margin: "10px", position: "relative" }}
                >
                  <img
                    src={img}
                    alt="Uploaded"
                    style={{
                      width: "150px",
                      height: "100px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleEditImage(img)}
                  />
                </div>
              ))} */}

              {imageTypes.map(
                (type) =>
                  imagesParType[type].length > 0 && (
                    <div key={type} className="mb-8">
                      <h3 className="text-lg font-semibold mb-4">
                        {type.replace(/_/g, " ")}
                      </h3>
                      <div className="grid grid-cols-3 gap-4">
                        {imagesParType[type].map((image, index) => (
                          <div key={index} className="relative group">
                            <img
                              src={image.src}
                              alt={`${type} ${index + 1}`}
                              className="w-full h-32 object-cover rounded"
                              onClick={() => handleEditImage(image.src, type)}
                            />
                            <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity">
                              <TrashIcon
                                className="h-6 w-6 text-red-500 hover:text-red-700 cursor-pointer"
                                onClick={() => {
                                  setImagesParType((prev) => ({
                                    ...prev,
                                    [type]: prev[type].filter(
                                      (_, i) => i !== index
                                    ),
                                  }));
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )
              )}
            </div>

            {/* Composant d'édition d'image avec Fabric.js */}
            {selectedImage && (
              <FabricCanvas
                imageSrc={selectedImage}
                onSave={(editedImage) => {
                  console.log("selectedImageType", selectedImageType);
                  setImagesParType((prev) => ({
                    ...prev,
                    [selectedImageType]: prev[selectedImageType].map((img) =>
                      img.src === selectedImage
                        ? { ...img, src: editedImage }
                        : img
                    ),
                  }));
                  setSelectedImage(null);
                  setSelectedImageType(null);
                }}
                onClose={() => {
                  setSelectedImage(null);
                  setSelectedImageType(null);
                }}
              />
            )}
            {selectedImageType && !selectedImage && (
              <ImageTypeSelector
                onSelect={(type) => handleImageUpload(type)}
                onCancel={() => setSelectedImageType(null)}
              />
            )}

            {/* Bouton de génération */}
            {/* <button
              onClick={generateWord}
              style={{
                marginTop: "20px",
                padding: "10px",
                backgroundColor: "blue",
                color: "white",
              }}
            >
              Générer Word
            </button> */}
          </div>
        )}
        {tab === "materiaux" && (
          <>
            <label
              className={`absolute btn btn-circle btn-primary  top-2 right-2  z-50 ${
                drawerOpen && "hidden"
              }`}
              onClick={() => setDrawerOpen(true)}
            >
              <FaList className="size-6" />
            </label>
            <Dialog
              open={drawerOpen}
              onClose={setDrawerOpen}
              className="relative z-50 "
            >
              {/* PANEL PRINCIPAL */}
              <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                  <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full  pl-10">
                    <TransitionChild
                      as={Fragment}
                      enter="transform transition ease-in-out duration-300"
                      enterFrom="translate-x-full"
                      enterTo="translate-x-0"
                      leave="transform transition ease-in-out duration-200"
                      leaveFrom="translate-x-0"
                      leaveTo="translate-x-full"
                    >
                      <DialogPanel className="pointer-events-auto relative w-screen max-w-md  bg-white shadow-xl">
                        {/* CONTENU */}
                        <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                          <div className="px-4 sm:px-6 flex items-center justify-between">
                            <DialogTitle className="text-base font-semibold text-gray-900">
                              Panel title
                            </DialogTitle>
                            <button
                              type="button"
                              onClick={() => setDrawerOpen(false)}
                              className="relative rounded-md text-gray-300 hover:text-white focus:ring-2 focus:ring-white focus:outline-none"
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                color="red"
                                aria-hidden="true"
                                className="size-6 "
                              />
                            </button>
                          </div>
                          <DialogPanel>
                            {/* Popup pour la saisie de quantité */}
                            {quantityPopup && (
                              <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
                                <div className="bg-white p-6 rounded-lg shadow-lg max-w-xs w-full">
                                  <h4 className="text-lg font-semibold mb-4">
                                    Saisir la quantité
                                  </h4>
                                  <input
                                    ref={quantityRef}
                                    type="number"
                                    min="1"
                                    className="input input-bordered w-full"
                                    placeholder="Quantité"
                                  />

                                  <div className="flex justify-end space-x-2 mt-4">
                                    <button
                                      onClick={() => setQuantityPopup(null)}
                                      className="btn btn-secondary"
                                    >
                                      Annuler
                                    </button>
                                    <button
                                      onClick={() => {
                                        const value = parseInt(
                                          quantityRef.current.value,
                                          10
                                        );
                                        if (value >= 1 && value <= 99) {
                                          quantityPopup.onValidate(value);
                                        }
                                      }}
                                      className="btn btn-primary"
                                    >
                                      Valider
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </DialogPanel>
                          <div className="relative mt-6 flex-1 px-4 sm:px-6">
                            <div className="join join-vertical ">
                              {materiaux.map((categorie) => (
                                <div
                                  key={categorie._id}
                                  className="collapse collapse-arrow join-item border-base-300 border"
                                >
                                  <input type="radio" name="collapse" />
                                  <div className="collapse-title font-medium">
                                    {categorie._id}
                                  </div>
                                  <div className="collapse-content">
                                    {categorie.souscategories.map(
                                      (souscategorie) => (
                                        <React.Fragment
                                          key={souscategorie.souscategorie}
                                        >
                                          {souscategorie?.souscategorie !==
                                            "Sans sous-catégorie" && (
                                            <div className="divider divider-primary text-sm my-2">
                                              {souscategorie.souscategorie}
                                            </div>
                                          )}

                                          {souscategorie.materiaux.map(
                                            (materiau) => (
                                              <div
                                                key={materiau.code}
                                                className="flex justify-between items-center w-full p-2 hover:bg-gray-50"
                                              >
                                                <p className="text-sm">
                                                  {materiau.description}
                                                </p>
                                                <PlusCircleIcon
                                                  className="h-6 w-6 text-green-200 hover:text-green-600 cursor-pointer flex-shrink-0"
                                                  onClick={() => {
                                                    if (
                                                      materiau?.saisiQuantity
                                                    ) {
                                                      setQuantityPopup({
                                                        materiau,
                                                        onValidate: (
                                                          quantity
                                                        ) => {
                                                          const materielText = ` ${materiau.description} - ${materiau.code} - Q${quantity} `;
                                                          setRecapMateriaux(
                                                            (prev) => [
                                                              ...prev,
                                                              {
                                                                value:
                                                                  materielText,
                                                              },
                                                            ]
                                                          );
                                                          setQuantityPopup(
                                                            null
                                                          );
                                                        },
                                                      });
                                                    } else {
                                                      const materielText = ` ${materiau.description} - ${materiau.code} - Q1 `;
                                                      setRecapMateriaux(
                                                        (prev) => [
                                                          ...prev,
                                                          {
                                                            value: materielText,
                                                          },
                                                        ]
                                                      );
                                                    }
                                                  }}
                                                />
                                              </div>
                                            )
                                          )}
                                        </React.Fragment>
                                      )
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </DialogPanel>
                    </TransitionChild>
                  </div>
                </div>
              </div>
            </Dialog>
            <div className="flex flex-col mt-16">
              {recapMateriaux.map((item, index) => (
                <div
                  key={index}
                  className="flex justify-between items-center w-full"
                >
                  <p>{item.value}</p>
                  <TrashIcon
                    className="h-6 w-6 text-red-200 hover:text-red-600 cursor-pointer flex-shrink-0"
                    onClick={() => {
                      setRecapMateriaux((prevRecap) =>
                        prevRecap.filter((_, i) => i !== index)
                      );
                    }}
                  />
                </div>
              ))}
            </div>
          </>
        )}
      </div>

      {/* Popup pour entrer les valeurs */}
      {popupData && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-xs w-full">
            <h4 className="text-lg font-semibold">Entrez les valeurs</h4>

            {/* Inputs pour XX */}
            {inputValues.map((value, index) => (
              <input
                key={`input-${index}`}
                type="text"
                className="input input-bordered w-full mt-2"
                value={value}
                onChange={(e) => handleInputChange(e, index)}
                placeholder={`Entrez une valeur`}
              />
            ))}

            {/* Selects pour XXX */}
            {popupData.choix &&
              selectValues.map((value, index) => (
                <select
                  key={`select-${index}`}
                  className="select select-bordered w-full mt-2"
                  value={value}
                  onChange={(e) => handleSelectChange(e, index)}
                >
                  <option value="">Sélectionnez une option</option>
                  {popupData.choix[index]?.map((option, optIndex) => (
                    <option key={optIndex} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              ))}

            <div className="flex justify-end space-x-2 mt-4">
              <button
                onClick={() => setPopupData(null)}
                className="btn btn-secondary"
              >
                Annuler
              </button>
              <button
                onClick={handleSubmitInput}
                disabled={
                  inputValues.some((v) => v === "") ||
                  selectValues.some((v) => v === "")
                }
                className="btn btn-primary"
              >
                Valider
              </button>
            </div>
          </div>
        </div>
      )}

      <button onClick={generateDocument} className="btn btn-primary">
        Générer le rapport
      </button>
    </div>
  );
};

export default FormulaireTechnicien;
