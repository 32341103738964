export const materiaux = [
  {
    _id: "CUIVRES",
    souscategories: [
      {
        souscategorie: "Sans sous-catégorie",
        materiaux: [
          {
            description: "CUIVRE U1000R02V 2 X 16 (50m)",
            code: "S6023863",
          },
          { description: "CUIVRE U1000R02V 2 X 25 (50m)", code: "S6023865" },
          { description: "CUIVRE U1000R02V 2 X 35", code: "S6023866" },
          { description: "CUIVRE U1000R02V 4 X 16 (50m)", code: "S6023898" },
          { description: "CUIVRE U1000R02V 4 X 25", code: "S6023904" },
          { description: "CUIVRE U1000R02V 4 X 35", code: "S6023907" },
        ],
      },
    ],
  },
  {
    _id: "CABLES",
    souscategories: [
      {
        souscategorie: "Sans sous-catégorie",
        materiaux: [
          {
            description: "HN 2x35 (250m)",
            code: "S6148108",
            saisiQuantity: true,
          },
          { description: "HN 4x35 (250m)", code: "S6148162" },
          { description: "CA ALU TOR SPO 2X25 -C 100m", code: "S6125074" },
          { description: "A ALU TOR SPO 4X25 - C 80m", code: "S6125117" },
        ],
      },
    ],
  },
  {
    _id: "COUPES-CIRCUITS",
    souscategories: [
      {
        souscategorie: "Sans sous-catégorie",
        materiaux: [
          { description: "CC unip. 60A", code: "S6940508" },
          { description: "CC unip. 60A sans patte", code: "S6940518" },
          { description: "CC unip. 90A sans patte", code: "S6940540" },
          { description: "CC Bipo 90A", code: "S6940036" },
          { description: "CC tetra 60A", code: "S6940038" },
          { description: "C/C FUSIBLE CYL 22X58 PERF", code: "S6940070" },
        ],
      },
    ],
  },
  {
    _id: "RACCORDS SOUTERRAINS",
    souscategories: [
      {
        souscategorie: "Sans sous-catégorie",
        materiaux: [
          { description: "SDI-CPI 240-35 v2006", code: "S6790194" },
          { description: "DDI-CPI 240-35 v2006", code: "S6790193" },
          { description: "DDI 240-35", code: "S6790183" },
          { description: "Boite jonction JRB/JRBSE 35²", code: "S6790113" },
          { description: "Bout perdu 35² (borgne)", code: "S6798665" },
          {
            description: "MALT ACCES BTS :cab. 25²+cos+boul/rond",
            code: "S5963560",
          },
        ],
      },
    ],
  },
  {
    _id: "COFFRETS",
    souscategories: [
      {
        souscategorie: "S20",
        materiaux: [
          { description: "Socle simple S20", code: "S6980035" },
          { description: "Socle double S20", code: "S6980036" },
          { description: "Couvercle S20", code: "S6980041" },
          { description: "Porte S20 BTR", code: "S6980027" },
          { description: "Planche S20 Tri", code: "S6980025" },
          { description: "C/C S20 Mono 90A ancien", code: "S6980028" },
          { description: "C/C S20 Tri 90A ancien", code: "S6980029" },
          { description: "Planch. S20 Mono ancien", code: "S6980024" },
          { description: "Pan S20 Mono/Tri avec perfo", code: "S6980195" },
          { description: "Borne substitu Compt Mono", code: "S6980336" },
          { description: "Borne substitu Compt Tri", code: "S6980337" },
          { description: "Module arrivée C/C Mono/Tri", code: "S6980136" },
          { description: "Module départ pan. Mono/Tri", code: "S6980137" },
          { description: "Fond de cuve S20 Mono/Tri", code: "S6980138" },
          { description: "Module départ C/C Emb Perfo", code: "S6980139" },
          { description: "Coffret S20 NU (sans porte)", code: "S6980021" },
          { description: "Repiquage S15 - S20", code: "S6980492" },
        ],
      },
      {
        souscategorie: "S22",
        materiaux: [
          { description: "SOCLE POUR COF. S22 IVR ELEC", code: "S6980460" },
          { description: "Repiquage S22", code: "S6980493" },
        ],
      },
      {
        souscategorie: "S15",
        materiaux: [
          { description: "Socle équipable S15", code: "S6980086" },
          {
            description: "BOITIER PRIMAIRE /BRT MONO 90A pour S15",
            code: "S6980069",
          },
          { description: "PAN AMOV/SUP BASE C/C COF62S15", code: "S6980079" },
          { description: "Triade Compteur LINKY", code: "S4074024" },
        ],
      },
    ],
  },
  {
    _id: "EQUIPEMENTS COFFRETS",
    souscategories: [
      {
        souscategorie: "Sans sous-catégorie",
        materiaux: [
          { description: "Grille de repiquage CIBE 3x35²", code: "S6980818" },
          { description: "Grille étoilement CIBE 150-3x35", code: "S6980819" },
          {
            description: "Kit tri adaptation pour cibe A2D2 avant 09/17",
            code: "S6980881",
          },
        ],
      },
    ],
  },

  {
    _id: "COFFRETS CIBE",
    souscategories: [
      {
        souscategorie: "MONO",
        materiaux: [
          { description: "Borne CIBE 60A", code: "S6980805" },
          { description: "Borne CIBE Brancht type 2", code: "S6980811" },
          { description: "Borne CIBE T2 équipée LINKY G1", code: "S6980915" },
          {
            description: "B. CIBE T2 CABLEE + LG3 + DISJ + 2 LIAI",
            code: "S6980918",
          },
          {
            description: "Ens. Borne CIBE mono typ2 linky G3",
            code: "S6980919",
          },
          {
            description: "Ens. Borne CIBE mono typ2 linky G1",
            code: "S6980916",
          },
          {
            description: "Coffret CIBE PP mono 60A (encastrement)",
            code: "S6980890",
          },
        ],
      },
      {
        souscategorie: "TRI",
        materiaux: [
          { description: "Borne CIBE GV tri type 2", code: "S6980813" },
          { description: "Borne CIBE T2 équipée LINKY G3", code: "S6980920" },
          { description: "Borne CIBE T2 équipée LINKY G1", code: "S6980917" },
        ],
      },
    ],
  },
  {
    _id: "MATERIELS DIVERS",
    souscategories: [
      {
        souscategorie: "Sans sous-catégorie",
        materiaux: [
          { description: "Queue de cochon a sceller 12X150", code: "S6827413" },
          {
            description: "ENSEMBLE QUEUE DE COCHON 12X110 AVEC CHEVILLE",
            code: "S6827260",
          },
          { description: "Plaque queue de cochon", code: "S6827144" },
          { description: "PINCE ANCRAGE PA 25", code: "S6828501" },
          {
            description: "PINCE ANCRAGE CROCHET REGLABLE PACR 25",
            code: "S6828504",
          },
          {
            description: "PINCE ANCRAGE POSE A DISTANCE PAD 25 fixe",
            code: "S6828508",
          },
          { description: "PAS 35 aéro-sout", code: "S6828657" },
          { description: "Hampe de 2m", code: "S6830621" },
          { description: "Bras de scellement 350", code: "S6831140" },
          { description: "Bras de scellement 700", code: "S6831142" },
          { description: "Ensemble plaque bride 55", code: "S6834057" },
          { description: "SCELLE ENEDIS (Sachet de 500)", code: "S1743700" },
          { description: "MARQUEUR DISQUE/BOULE SOUT", code: "S6888356" },
          { description: "Douille augmentatrice", code: "S6902049" },
          { description: "Panneau d'affichage ENEDIS", code: "S3705415" },
        ],
      },
    ],
  },
  {
    _id: "DISJONCTEURS",
    souscategories: [
      {
        souscategorie: "MONO",
        materiaux: [
          { description: "Mono 15/45", code: "S6930044" },
          { description: "Disj Mono 60A Type S", code: "S6930064" },
          { description: "DISJ NON DIF BIP 1PP 15-45A", code: "S6931011" },
          { description: "DISJ NON DIF BIP 1PP 30-60A", code: "S6931013" },
          { description: "Disj mono 15/45 type S", code: "S6930061" },
        ],
      },
      {
        souscategorie: "TRI",
        materiaux: [
          { description: "Tri 15/45", code: "S6930045" },
          { description: "DISJ TRI TYPE S 10A30A", code: "S6930066" },
          { description: "DISJ TRI TYPE S 30A60A", code: "S6930067" },
          { description: "DISJ TRI NON DIF 30A", code: "S6931020" },
          { description: "DISJ TRI NON DIF 60A", code: "S6931023" },
        ],
      },
    ],
  },
  {
    _id: "PANNEAUX DE COMPTAGE",
    souscategories: [
      {
        souscategorie: "Sans sous-catégorie",
        materiaux: [
          { description: "25X22 POUR CPTR+DISJ", code: "S6981155" },
          { description: "MONO 25X50 POUR CC+CPTR+DISJ", code: "S6981210" },
          {
            description: "Panneau mono 250x225 faible épaisseur",
            code: "S6981195",
          },
          { description: "TRI 25X55 POUR CPTR+DISJ", code: "S6981220" },
          { description: "TRI 25X80 POUR CC+CPTR+DISJ", code: "S6981222" },
        ],
      },
    ],
  },
  {
    _id: "COMPTEURS",
    souscategories: [
      {
        souscategorie: "Sans sous-catégorie",
        materiaux: [
          { description: "CPT LINKY MONO G3 90A", code: "S4074054" },
          { description: "CPT LINKY MONO G1 90A", code: "S4074061" },
          { description: "COMPTEUR CBE MONO", code: "S4074038" },
          { description: "COMPTEUR LINKY G3 TRIPHASE", code: "S4074058" },
          { description: "COMPTEUR LINKY G1 TRIPHASE", code: "S4074062" },
          { description: "COMPTEUR CBE TRI", code: "S4074138" },
        ],
      },
    ],
  },
  {
    _id: "FUSIBLES",
    souscategories: [
      {
        souscategorie: "Sans sous-catégorie",
        materiaux: [
          { description: "Couteau neutre T00", code: "S6943512" },
          { description: "Fusible T00 60A AD", code: "S6943513" },
          { description: "Fusible cylind. 22x58 60A", code: "S6942638" },
          { description: "Cart. Neutre cylind. 22x58", code: "S6942669" },
        ],
      },
    ],
  },
  {
    _id: "PROTECTIONS CABLES",
    souscategories: [
      {
        souscategorie: "Sans sous-catégorie",
        materiaux: [
          {
            description: "Goulotte protection Aerosout 35 x 35 grise",
            code: "S6880540",
          },
          { description: "Pied de goulotte 60x40", code: "S6910322" },
        ],
      },
    ],
  },
  {
    _id: "matériel PMES",
    souscategories: [
      {
        souscategorie: "Sans sous-catégorie",
        materiaux: [
          {
            description: "LOT de 50 DISPOSITIF BLOCAGE DISJONCTEUR",
            code: "S6771034",
          },
          {
            description: "LOT 50 SCELLE DISPOSITIF BLOCAGE DISJ.",
            code: "S6771033",
          },
          {
            description: "LOT 50 ETIQUETTES BLOCAGE DISJONCTEUR",
            code: "S6771035",
          },
        ],
      },
    ],
  },
  {
    _id: "SUPPORTS",
    souscategories: [
      {
        souscategorie: "Sans sous-catégorie",
        materiaux: [
          { description: "Bois 9m/140", code: "S5807179" },
          { description: "Bois 10m/190", code: "S5807180" },
          { description: "Bois 11m/190", code: "S5807181" },
        ],
      },
    ],
  },
];

export const descTravaux = [
  {
    value: "Raccordement XXX",
    label: "Raccordement boite souterraine / sur grille existante",
    choix: [["boîte souterraine", "sur grille existante"]],
    type: "enedis",
    codesMateriaux: ["S6981155", "S6930044"],
  },
  {
    value: "Faire une tranchée de XX m en type XXX pour la liaison A",
    label:
      "Faire une tranchée de XX m en type XX (1 – Accotement, 2 – Chaussée) pour la liaison A",
    choix: [["I", "II", "IIbis", "III"]],
    type: "enedis",
    materiauxParChoix: {
      I: ["S6148162"], // Matériaux spécifiques pour "4x35"
      II: ["S6148108"], // Matériaux spécifiques pour "2x35"
    },
  },
  {
    value: "Pose d'une borne CIBE XXX",
    label:
      "Pose d’une borne CIBE encastrée dans la niche réalisée par le client / en limite de propriété",
    choix: [["dans la niche réalisée par le client", "en limite de propriété"]],
    type: "enedis",
  },
  {
    value: "Passer un câble de XX m pour la liaison B en XXX HN",
    choix: [["4x35", "2x35"]],
    type: "enedis",
  },
  {
    value: "Pose d’un tableau 25x22 compteur disjoncteur monophasé",
    type: "enedis",
  },
  {
    value: "Pose d’un tableau 25x55 compteur disjoncteur triphasé",
    type: "enedis",
  },
  {
    value: "Raccordement sur poteau via RAS 4x25 PRC longueur XX m",
    type: "enedis",
  },
  {
    value:
      "Réaliser une niche (hauteur720 mm x Largeur 250 mm x profondeur 200 mm) afin d’encastrer la borne CIBE",
    type: "client",
    codesMateriaux: ["S6981155", "S6930044"],
  },
  {
    value:
      "Réaliser une tranchée et passer un fourreau rouge diamètre 90mm de la borne CIBE jusqu'à l'aplomb du futur tableau de comptage",
    type: "client",
  },
  {
    value: "Signer la TVA à 10%",
    label: "Signer la TVA à 10% (A NE PAS METTRE SI BCHT NEUF)",
    type: "client",
  },
  {
    value: "Prévoir la reprise de l’installation",
    type: "client",
  },
  {
    value:
      "4 cas particulier de l'utilisation des cloisons réalisées en plaque de plâtre : -Cas 1, la cloison alvéolaire doit être doublée par une plaque de plâtre d'une épaisseur minimale de 13 mm de part et d'autre. -Cas 2, lorsqu'une simple plaque est mise en œuvre, les fixations du panneau de contrôle doivent se faire impérativement dans le mur M0. -Cas 3, chaque face de la cloison sur ossature doit être constituée d'au moins 2 plaques de BA13 ou d'une en BA25. -Cas 4, le mur en matériaux composite ou en bois doit être protégé par deux plaques de BA13 ou d'une BA25",
    label:
      "Prévoir une surface plane de type enduit à l’endroit convenu -	La paroi du bâtiment sur laquelle est fixé le panneau de comptage doit être incombustible (M0) et non métallique et ne doit être exposée aux vibrations.",
    type: "client",
  },
  {
    value: "Matérialisation de l'emplacement de la borne",
    type: "client",
  },
];
